import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('full-view',{attrs:{"title":"Business Settings"}},[_c(VCard,{attrs:{"flat":""}},[_c(VList,{attrs:{"subheader":"","three-line":""}},[_c(VSubheader,[_vm._v("Stores Management")]),_c(VListItem,{attrs:{"to":"store-profiles"}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-store")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('app.route.storeProfiles')))]),_c(VListItemSubtitle,[_vm._v(" Manage your store profiles ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }